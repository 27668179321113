import React, { useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import styled from 'styled-components';
import { Fade, Backdrop, Modal, FormControlLabel, Checkbox, Typography, MenuItem,Select, FormControl, Box } from '@mui/material';


const StyledSelect = styled(Select)`
  .MuiSelect-select {
    border-radius: 4px;
    padding: 12px 20px;
    font-size: 16px;
    height: 40px;
    box-sizing: border-box;
    width: 100%;
  }
`;

const MyModal = (isOpen) => {

  const [Name, setName] = useState(  "");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState( "");
  const [postcode, setPostcode] = useState(  "");
  const [address, setAddress] = useState("");
  const [town,setTown]=useState('');
  const [password, setPassword] = useState( "");
  const [checked, setChecked] = useState(true);
  const [image, setImage] = useState([]);
  const [data,setData]=useState([]);
  const [providerId,setProviderId]=useState('');
  const [selectedOption, setSelectedOption] = useState("Select Scan Provider");

  
  const handleImageChange = (event) => {
    ///console.log('event',event.target.files[0].name);
    const file = event.target.files[0]; 
    if (file) {
      setImage(file); 
    }
  }
  
  useEffect(()=>{
    setSelectedOption('Select Scan Provider')
  },[isOpen])


  useEffect(()=>{

    fetch(process.env.REACT_APP_API_URL + `/scanproviders/getallscanproviders`, {
      method: 'GET',
    })
      .then(response => response.json())
      .then(data => {
        console.log('data>>>>>>>>>>>>>>',data);
        setData(data);

      })
  },[])

  const handleChangeCheck = (event) => {
    setChecked(event.target.checked);
  };

  const handleChange = (event) => {

    //console.log("event target",event.target);
    const value=event.target.value;
    const split=value.split('#');
  
   setProviderId(split[1]);
   setSelectedOption(event.target.value); 
  };


  useEffect(()=>{
    setChecked(true);
  },[isOpen])


  const handleSave = (e) => {
    console.log('kkkkkkkkkkkkkkk')
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', Name);
    formData.append('email', email);
    formData.append('image', image); // Ensure 'image' is a File or Blob object
    formData.append('phone_number', phoneNumber);
    formData.append('location',postcode);
    formData.append('town',town);
    formData.append('address',address);
    formData.append('password', password);
    formData.append('scanprovider_id_fkey', providerId);

     fetch( process.env.REACT_APP_API_URL+`/auth/register-centers`, {
        method: 'POST',
        body: formData
      })
      .then(response => response.json())
      .then(data => {
        console.log(data.message);
        if(data.message){
        isOpen.onAdd({
          center_id:data.UserId,
          message:data.message,
          name:Name,
          email:email,
          phonenumber:phoneNumber,
          address:address,
          status:true,
          checked:checked
        });
      }else{
        isOpen.onAdd({
          error:data.error,
        });
      }
     })
    
     
  };


  const renderValue = (selected) => {
    ///console.log("selected>",selected)
    return selected.split('#')[0];
  };
  return (
    <>
    

      <Modal
        open={isOpen.isOpen}
        onClose={isOpen.onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen.isOpen}>
          <MDBox
            component="div"
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgColor="white"
            shadow="md"
            borderRadius="lg"
            p={4}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              maxWidth: '600px',
            }}
          >
            
            <MDBox>
              <Grid container spacing={2}>
                <Grid item xs={12}>
               
                  <MDInput
                    placeholder="Name"
                    
                    style={{ width: '100%' }} 
                    onChange={(event) => setName(event.target.value)}
                  />
                </Grid>
                
                <Grid item xs={12}>
               
                  <MDInput
                    placeholder="Email"
                    type="email"
                    
                    style={{ width: '100%' }} 
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
               
                  <MDInput
                    placeholder="Phone Number"
                   
                    style={{ width: '100%' }} 
                    onChange={(event) => setPhoneNumber(event.target.value)}
                  />
                </Grid>

                
                <Grid item xs={12}>
               
                  <MDInput
                    placeholder="First line of Address"
                    style={{ width: '100%' }} 
                    onChange={(event) => setAddress(event.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
               
               <MDInput
                 placeholder="Town/City"
                 style={{ width: '100%' }} 
                 onChange={(event) => setTown(event.target.value)}
               />
             </Grid>

                <Grid item xs={12}>
                
                <MDInput
                  placeholder="Postcode"
                  style={{ width: '100%' }} 
                  onChange={(event) => setPostcode(event.target.value)}
                />
              </Grid>


                <Grid item xs={12}>

                <StyledSelect
                  id="demo-simple-select"
                  value={selectedOption}
                  onChange={handleChange}
                  renderValue={renderValue}
                  style={{ width: '100%' }} 
                >
                  <MenuItem value="Select Scan Provider">Select Scan Provider</MenuItem>
                  {data.map((item) => (
                 <MenuItem key={item.id} value= {item.name+"#"+item.id} >
                   {item.name}
                 </MenuItem>
               ))}
                </StyledSelect>
            
            </Grid>
                   
                <Grid item xs={12}>
               
                  <MDInput
                    type="file"
                    style={{ width: '100%' }} 
                    onChange={handleImageChange} 
                  />
                </Grid>

                <Grid item xs={12}>
                
                  <MDInput
                   type="password"
                    placeholder="Password"
                    style={{ width: '100%' }} 
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </Grid>
                
              </Grid>
              <div>
            <FormControlLabel
              control={<Checkbox checked={checked} onChange={handleChangeCheck} />}
              label="All Time Available"
            />
            
          </div>
              <MDBox
                display="flex"
                justifyContent="flex-end"
                gap={2}
                mt={2}
              >
                <MDButton
                  color="success"
                  onClick={handleSave}
                >
                  Add Center 
                </MDButton>
                <MDButton
                  color="secondary"
                  variant="outlined"
                  onClick={isOpen.onClose}
                >
                  Close
                </MDButton>
              </MDBox>
            </MDBox>
           
          </MDBox>
        </Fade>
      </Modal>
    </>
  );
};

export default MyModal;