/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { v4 as uuidv4 } from 'uuid';

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/signin_background.png";
import axios from "axios";
import MDAlert from "components/MDAlert";
function Basic() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  console.log('errror>',error);

  const alertContent = (error) => (
    <MDTypography variant="body2" color="white">
     {error}
    </MDTypography>
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
  
   
    try {
      console.log("email and password", email, password);
    
       const response = await axios.post(
         process.env.REACT_APP_API_URL +"/auth/login-admin",
          {
            email,
            password,
          }
        );
      
        console.log("response data", response);
        if (response.data.error) {
          setError(response.data.error);
        } else {
          setError("");
         navigate(`/authentication/verify_code?email=${email}&user=Admin`)
        }
      } catch (error) {
        console.error("error is here", error);
        setMessage("");
        setError(error.response.data.error);
      }
    
  };
  return (
    <BasicLayout image={bgImage}>
      <Card sx={{ height:'250px' }}>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white"mt={1}>
            Sign in
          </MDTypography>
        
        </MDBox>
        <MDBox pt={1} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput placeholder="Email"
                       autoComplete="email" 
                       type="email"
                       required
                       name='email'
                       value={email}
                       onChange={(e) => setEmail(e.target.value)} 
              fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput  name='password'
                        placeholder="Password"
                        type="password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                fullWidth />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
           
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
              </MDTypography>
            </MDBox>
            <MDBox mt={1} mb={1}>
              <MDButton variant="gradient" type="submit" color="info" fullWidth>
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
                {message && <MDAlert color="warning">
                  {alertContent(message)}
                </MDAlert>}
                {error && <MDAlert color="error">
                  {alertContent(error)}
                </MDAlert>}
            </MDBox>
           
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
