import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog(isstatusOpen) {

        const handlestatus=()=>{
        const referral_id=isstatusOpen.status_id;
        let status=isstatusOpen.status;        
        if(status==true){
          status=false;
        }else{
          status=true;
        }
          fetch( process.env.REACT_APP_API_URL+`/auth/updatereferralstatus/${referral_id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              status:status,
              
            })
          })
          .then(response => response.json())
          .then(data => {
            if(data.message){
              isstatusOpen.onstatusUpdate({
                message:data.message,
                status:status,
                id:referral_id,
              })
            }else{
              isstatusOpen.onstatusUpdate({
                error:data.error,
              
              })
            }
           
          })
          .catch((error) => {
            console.error('Error:', error);
          });

        
        }



  return (
    <React.Fragment>
   
      <Dialog
        open={isstatusOpen.isstatusOpen}
        onClose={isstatusOpen.onstatusClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Change Status?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to Change Status of Referral?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={isstatusOpen.onstatusClose}>Cancle</Button>
          <Button onClick={handlestatus} autoFocus>
            Change
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
