import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog(isOpen) {


    console.log(isOpen);

        const handleReview=()=>{
        const booking_id=isOpen.id.props.children;
          let formData=new FormData();
          formData.append('status','inreview');
        try{
            fetch( process.env.REACT_APP_API_URL+`/booking/bookingupdatecenter/${booking_id}`, {
              method: 'PUT',
              body:formData
            })
            .then(response => response.json())
            .then(data => {
              if(data.message){
                isOpen.onUpdate({
                  booking_id:booking_id,
                  message:data.message, 
                  status:'inreview',
                });
              }else{
                isOpen.onUpdate({
                  error:data.error, 
                });
              }
           
            })

          }catch(error){
            console.log('error',error);
          }
           
          
        }

  return (
    <React.Fragment>
   
      <Dialog
        open={isOpen.isOpen}
        onClose={isOpen.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Assign Doctor for Review?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to assign this doctor for review?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={isOpen.onClose}>Cancle</Button>
          <Button onClick={handleReview} autoFocus>
           Assign
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
