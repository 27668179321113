import React, { useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import { Fade, Backdrop, Modal } from '@mui/material';

const MyModal = (isOpen) => {

  const [firstName, setFirstName] = useState(  "");
  const [lastName, setLastName] = useState(  "");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState( "");
  const [clinicname,setClinicName]=useState('');
  const [postcode,setPostCode]=useState('');

 
  const [password, setPassword] = useState( "");



  const handleSave = () => {
  fetch( process.env.REACT_APP_API_URL+`/auth/register-referral`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstname:firstName,
          lastname:lastName,
          email:email,
          phonenumber:phoneNumber,
          clinicname:clinicname,
          location:postcode,
          password:password
        })
      })
      .then(response => response.json())
      .then(data => {
      if(data.message){
        isOpen.onAdd({
          referral_id:data.UserId,
          message:data.message,
          firstname:firstName,
          lastname:lastName,
          email:email,
          phonenumber:phoneNumber,
          clinicname:clinicname,
          postcode:postcode,
          status:true
        });
      }else{
        isOpen.onAdd({
          error:data.error,
        });
      }
        
      })
     
  };
  return (
    <>
    

      <Modal
        open={isOpen.isOpen}
        onClose={isOpen.onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen.isOpen}>
          <MDBox
            component="div"
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgColor="white"
            shadow="md"
            borderRadius="lg"
            p={4}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              maxWidth: '600px',
            }}
          >
            
            <MDBox>
              <Grid container spacing={2}>
                <Grid item xs={12}>
               
                  <MDInput
                    placeholder="First Name"
                    style={{ width: '100%' }} 
                    onChange={(event) => setFirstName(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
              
                  <MDInput
                    placeholder="Last Name"
                    style={{ width: '100%' }} 
                    onChange={(event) => setLastName(event.target.value)}
                  />
                </Grid>

                
                
                <Grid item xs={12}>
               
                  <MDInput
                    placeholder="Email"
                    type="email"
                    
                    style={{ width: '100%' }} 
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
               
                  <MDInput
                    placeholder="Phone Number"
                   
                    style={{ width: '100%' }} 
                    onChange={(event) => setPhoneNumber(event.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
             
                  <MDInput
                    placeholder="Clinic Name"
                    style={{ width: '100%' }} 
                    onChange={(event) => setClinicName(event.target.value)}
                  />
                </Grid>
                
                <Grid item xs={12}>
          
                  <MDInput
                    placeholder="Post Code"
                    style={{ width: '100%' }} 
                    onChange={(event) => setPostCode(event.target.value)}
                  />
                </Grid>
            
                   
                <Grid item xs={12}>
               
                  <MDInput
                    type="password"
                    placeholder="Password"
                    style={{ width: '100%' }} 
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </Grid>
                
              </Grid>
              <MDBox
                display="flex"
                justifyContent="flex-end"
                gap={2}
                mt={2}
              >
                <MDButton
                  color="success"
                  onClick={handleSave}
                >
                  Add Referral 
                </MDButton>
                <MDButton
                  color="secondary"
                  variant="outlined"
                  onClick={isOpen.onClose}
                >
                  Close
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Fade>
      </Modal>
    </>
  );
};

export default MyModal;