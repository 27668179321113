/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

function SignOut() {

  const navigate = useNavigate();

  useEffect(() => {
    console.log("Logout the admin user.")
    
    localStorage.removeItem('user');
    localStorage.removeItem('jwt_token');

    navigate('/authentication/sign-in')
  }, [])

}

export default SignOut;
