import React, { useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import { Fade, Backdrop, Modal, Box } from '@mui/material';

const MyModal = (isOpen) => {

  const [formData,setFormData]=useState({
    firstname:'',
    lastname:'',
    email:'',
    phonenumber:'',
    location:'',
    description:'',
    image:''
  })





  useEffect(() => {
      try {
        fetch(process.env.REACT_APP_API_URL + `/auth/getadminbyid/${isOpen.admin_id}`, {
          method: 'GET',
        })
          .then(response => response.json())
          .then(data => {
            setFormData(data);
          })

      } catch (error) {
        console.error('Error parsing stored user data:', error);
      }
   

  }, [isOpen]);

  const handleChange=(event)=>{
    console.log(event.target.name);
    const {value,name,type,files}=event.target;

    if(type==='file'){
      setFormData({...formData,[name]:files[0]});
    }else{
      setFormData({...formData,[name]:value})
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('id', isOpen.admin_id);
    console.log('data', formData);
  
    
    const data = new FormData();
  
    // Append other form fields
    data.append('firstname', formData.firstname);
    data.append('lastname', formData.lastname);
    data.append('email', formData.email);
    data.append('phonenumber', formData.phonenumber);
    data.append('location', formData.location);
    data.append('description', formData.description);
    data.append('image', formData.image);
  
    fetch(`${process.env.REACT_APP_API_URL}/auth/updateadmin/${isOpen.admin_id}`, {
      method: 'PUT',
      body: data, 
    })
      .then(response => response.json())
      .then(data => {
        console.log('dataaa', data);
        if (data.message) {
          isOpen.onUpdate({
            message: data.message,
            update_data: {
              firstname:formData.firstname,
              lastname:formData.lastname,
              email:formData.email,
              phonenumber:formData.phonenumber,
              description:formData.description,
              location:formData.location,
              image:data.filepath

            },
            status: true,
          });
        } else {
          isOpen.onUpdate({
            error: data.error,
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };
  return (
    <>
    

      <Modal
        open={isOpen.isOpen}
        onClose={isOpen.onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen.isOpen}>
          <MDBox
            component="div"
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgColor="white"
            shadow="md"
            borderRadius="lg"
            p={4}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              maxWidth: '600px',
            }}
          >
            
            <MDBox>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
               
                  <MDInput
                    name='firstname'
                    placeholder="First Name"
                    style={{ width: '100%' }} 
                    value={formData.firstname}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>

                  <MDInput
                    name='lastname'
                    placeholder="Last Name"
                    style={{ width: '100%' }} 
                    value={formData.lastname}
                    onChange={handleChange}
                  />
                </Grid>
                
                <Grid item xs={12}>
               
                  <MDInput
                   name='email'
                    placeholder="Email"
                    type="email"
                    value={formData.email}
                    style={{ width: '100%' }} 
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
               
                  <MDInput
                   name='phonenumber'
                    placeholder="Phone Number"
                   value={formData.phonenumber}
                    style={{ width: '100%' }} 
                    onChange={handleChange}
                  />
                </Grid>

               
                
            
                   
                <Grid item xs={12}>
               
                  <MDInput
                   name='location'
                   type="text"
                   value={formData.location}
                    placeholder="location"
                    style={{ width: '100%' }} 
                    onChange={handleChange}
                  />
                </Grid>

                   
                <Grid item xs={12}>
             
                  <MDInput
                  name='description'
                   type="text"
                    placeholder="Description"
                    value={formData.description}
                    style={{ width: '100%' }} 
                    onChange={handleChange}
                  />
                </Grid>
                
                <Grid item xs={12}>
               
                  <MDInput
                  name='image'
                   type="file"
                    style={{ width: '100%' }} 
                    onChange={handleChange}
                  />
                </Grid>

                {formData.image ?
                  (<Box
                    component="img"
                    src={formData.image}
                    alt="image"
                    sx={{ width: 150, height: 100  ,mt:2,mx:2}} 
                  />)
                  :('')
                  }
                
              </Grid>
              <MDBox
                display="flex"
                justifyContent="flex-end"
                gap={2}
                mt={2}
              >
                <MDButton
                  color="success"
                  type="submit"
                 
                >
                  Update
                </MDButton>
                <MDButton
                  color="secondary"
                  variant="outlined"
                  onClick={isOpen.onClose}
                >
                  Close
                </MDButton>
              </MDBox>
              </form>
            </MDBox>
          </MDBox>
        </Fade>
      </Modal>
    </>
  );
};

export default MyModal;