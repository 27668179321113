/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// import MyModal from '../../components/Blogs/EditPopUp'
import DeleteModal from '../../components/Blogs/DeletePopUp'
// Data
import blogsTableData from "layouts/tables/data/blogsTableData";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

function Tables() {

  const navigate = useNavigate();
  // const location = useLocation();

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [delete_id, setDeleteID] = useState('');

  const [message, setMessage] = useState('');
  const [modelTitle, setModelTitle] = useState('');

  const [successSB, setSuccessSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);


  const [errorSB, setErrorSB] = useState(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);




  const [search, setSearch] = useState('');



  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={modelTitle}
      content={message}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={modelTitle}
      content={message}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  const openDeletePopUp = (data) => {
    console.log('deleeee',data);
    if (data.post_id) {
      setDeleteID(data.post_id);
      setIsDeleteOpen(true);
    } else {
      setDeleteID(data.post_id.props.children.props.children);
      setIsDeleteOpen(true);
    }

  }


  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const closeDeletePopup = () => {
    setIsDeleteOpen(false);

  }


  const column = { Header: "Action", accessor: "action" };
  const { columns: tableColumns, rows: tableRows } = blogsTableData();


  const deleteupdate = (value) => {
    if (value.message) {
      setIsDeleteOpen(false);
      deleteRowById(value.id);
      setMessage(value.message);
      setModelTitle('Deleted');
      openSuccessSB();
    } else {
      setIsDeleteOpen(false);
      //deleteRowById(value.id);
      setMessage(value.error);
      setModelTitle('Error');
      openErrorSB();
    }

  }

  const deleteRowById = (id) => {
    console.log('id,,', id);
    setRows(prevRows => prevRows.filter(row => row.post_id.props.children.props.children != id ? row : null));
  };
  useEffect(() => {

    if (rows.length > 0 || search != '') {
      return
    }

    const updatedRows = tableRows.map((row) => (
      {
        ...row, action:
          <MDTypography component="a" href="#" variant="caption" color="success" fontWeight="medium" >
            <Link to={`/skannr_blogs/edit/${row.post_id.props.children.props.children}`}><EditIcon fontSize="medium" color="secondary" /> </Link> 
            <span onClick={() => openDeletePopUp(row)}> <DeleteIcon fontSize="medium" color="error" /> </span>
          </MDTypography>
      }

    ))

    setColumns([...tableColumns, column]);
    setRows(updatedRows);

  }, [tableColumns, rows]);

  const handleSearch = (query) => {

    const updatedRows = tableRows.map((row) => (

      {
        ...row, action:
          <MDTypography component="a" href="#" variant="caption" color="success" fontWeight="medium" >
          <Link to={`/skannr_blogs/edit/${row.id.props.children.props.children}`}><EditIcon fontSize="medium" color="secondary" /> </Link> <span onClick={() => openDeletePopUp(row)}> <DeleteIcon fontSize="medium" color="error" /> </span>
          </MDTypography>
      }

    ))

    console.log("updatedRows>", updatedRows)


    const searchTerm = query.toLowerCase();
    const filteredData = updatedRows.filter((row) => {
      //console.log(row.name.props.children.toLowerCase())
      const rowNameValue = row.title.props.children.toLowerCase();
      const rowStatusValue = row.status.props.children.toLowerCase();
      return rowNameValue.includes(searchTerm) || rowStatusValue.includes(searchTerm);
    });

    console.log("filteredData>", filteredData)

    setRows(filteredData);


  };



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  All Blogs
                </MDTypography>
              </MDBox>

              <MDBox pt={3}>


                <MDBox width="12rem" ml="auto" mr="1em">
                  <MDInput
                    placeholder="filter by blog slug, status, title, or author"
                    value={search}
                    size="small"
                    fullWidth
                    onChange={({ currentTarget }) => {
                      setSearch(currentTarget.value);
                      handleSearch(currentTarget.value);
                    }}
                  />
                </MDBox>

                <MDBox width="12rem" ml="auto" mr="1em" mt='2rem'>
                <Link to={"/skannr_blogs/add/"}>
              <MDButton variant="gradient" color="info" >Publish New Blog</MDButton>
              </Link>
              </MDBox>

                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={10}
                  showTotalEntries={true}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>

        </Grid>
      </MDBox>
      <Footer />
      <MDBox
        position="fixed"
        bottom={0}
        right={0}
        p={3}
        zIndex="modal"
      >
      {
        // isEditOpen && <MyModal isOpen={isEditOpen} onClose={closeEditPopup} id={id} title={title} body={body} summary={summary} seoTitle={seoTitle} metaDescription={metaDescription} featuredImageUrl={featuredImageUrl} featuredImageAlt={featuredImageAlt} onUpdate={update} />
      }
      {
        isDeleteOpen && <DeleteModal isdeleteOpen={isDeleteOpen} ondeleteClose={closeDeletePopup} delete_id={delete_id} ondeleteUpdate={deleteupdate} />
      }
      {renderSuccessSB}
      {renderErrorSB}
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
