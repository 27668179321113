import React, { useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import styled from 'styled-components';
import { Fade, Backdrop, Modal, Box,Select, MenuItem} from '@mui/material';


const ITEM_HEIGHT = 48;  // Height of each item in the dropdown
const MENU_MAX_HEIGHT = 200;  // The height you want before the scroll appears

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: MENU_MAX_HEIGHT,  // Maximum height of the dropdown
      width: 250,  // Width of the dropdown (optional, based on your design)
    },
  },
};


const StyledSelect = styled(Select)`
  .MuiSelect-select {
    border-radius: 4px;
    padding: 12px 20px;
    font-size: 16px;
    height: 40px;
    box-sizing: border-box;
    width: 100%;
   
  }
`;



const MyModal = (isOpen) => {

  const [Name, setName] = useState(  "");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState( "");
  const [oneCenterDetail, setOneCenterDetail] = useState({});
  const [location,setLocation]=useState('');
  const [image, setImage] = useState([]);
  const [data,setData]=useState([]);
  const [providerId,setProviderId]=useState('');
  const [scanProvidername,setScanProviderName]=useState('')
  const [selectedOption, setSelectedOption] = useState("Select Scan Provider");

  const handleImageChange = (event) => {
    ///console.log('event',event.target.files[0].name);
    const file = event.target.files[0]; 
    if (file) {
      setImage(file); 
    }
  }


  useEffect(()=>{
    setName(isOpen.name);
    setEmail(isOpen.email);
    setPhoneNumber(isOpen.phone_number );
    setLocation(isOpen.address);
    setSelectedOption(isOpen.scanprovider)
  },[isOpen])

  useEffect(()=>{
    fetch( process.env.REACT_APP_API_URL+`/auth/getcenterbyid/${isOpen.id}`, {
      method: 'GET',
    })
    .then(response => response.json())
    .then(data => {
      setOneCenterDetail(data);
     
    })
  },[])


  
  useEffect(()=>{

    fetch(process.env.REACT_APP_API_URL + `/scanproviders/getallscanproviders`, {
      method: 'GET',
    })
      .then(response => response.json())
      .then(data => {
        console.log('data>>>>>>>>>>>>>>',data);
        setData(data);

      })
  },[])

  const handleChange = (event) => {

    //console.log("event target",event.target);
    const value=event.target.value;
    const split=value.split('#');
  
   setProviderId(split[1]);
   setScanProviderName(split[0]);
   setSelectedOption(event.target.value); 
  };

  const handleSave = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', Name);
    formData.append('email', email);
    formData.append('image', image); // Ensure 'image' is a File or Blob object
    formData.append('phone_number', phoneNumber);
    formData.append('address',location);
    formData.append('scanprovider_id', providerId);
    

    const center_id=isOpen.id;
  fetch( process.env.REACT_APP_API_URL+`/auth/updatecenter/${center_id}`, {
        method: 'PUT',
       
        body: formData
      })
      .then(response => response.json())
      .then(data => {
         if(data.message){
          isOpen.onUpdate({
            message:data.message,
            center_id:center_id,
            name:Name,
            email:email,
            phonenumber:phoneNumber,
            address:location,
            scanprovider:scanProvidername
          });
         }else{
          isOpen.onUpdate({
           error:data.error
          });
         }
       
       
      })
     
  };


  const renderValue = (selected) => {
    ///console.log("selected>",selected)
    return selected.split('#')[0];
  };

  return (
    <>
    

      <Modal
        open={isOpen.isOpen}
        onClose={isOpen.onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen.isOpen}>
          <MDBox
            component="div"
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgColor="white"
            shadow="md"
            borderRadius="lg"
            p={4}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              maxWidth: '600px',
            }}
          >
            <MDBox>
            <MDBox component="form" role="form" onSubmit={handleSave} >
              <Grid container spacing={2}>
                <Grid item xs={12}>
               
                  <MDInput
                    placeholder="Name"
                    value={Name}
                    style={{ width: '100%' }} 
                    onChange={(event) => setName(event.target.value)}
                  />
                </Grid>
                
                <Grid item xs={12}>
                
                  <MDInput
                    placeholder="Email"
                    type="email"
                    value={email}
                    style={{ width: '100%' }} 
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </Grid>

                 
                <Grid item xs={12}>
                
                  <MDInput
                    placeholder="First line of Address"
                    type="location"
                    value={location}
                    style={{ width: '100%' }} 
                    onChange={(event) => setLocation(event.target.value)}
                  />
                </Grid>


                


                <Grid item xs={12}>
               
                  <MDInput
                    placeholder="Phone Number"
                    value={phoneNumber}
                    style={{ width: '100%' }} 
                    onChange={(event) => setPhoneNumber(event.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>

                <StyledSelect
                  id="demo-simple-select"
                  value={selectedOption}
                  onChange={handleChange}
                  renderValue={renderValue}
                  style={{ width: '100%' }} 
                  MenuProps={MenuProps}
                >
                  <MenuItem value="Select Scan Provider">Select Scan Provider</MenuItem>
                  {data.map((item) => (
                <MenuItem key={item.id} value= {item.name+"#"+item.id} >
                  {item.name}
                </MenuItem>
                ))}
                </StyledSelect>

                </Grid>

                
                <Grid item xs={12}>
                
                  <MDInput
                    type="file"
                    style={{ width: '100%' }} 
                    onChange={handleImageChange} 
                  />
                  {oneCenterDetail.image &&
                  <Box
                    component="img"
                    src={oneCenterDetail.image}
                    alt="image"
                    sx={{ width: 150, height: 100  ,mt:2}} 
                  />
                  }
                </Grid>

              </Grid>
              <MDBox
                display="flex"
                justifyContent="flex-end"
                gap={2}
                mt={2}
              >
                <MDButton
                  color="success"
                  type='submit'
                >
                  Update
                </MDButton>
                <MDButton
                  color="secondary"
                  variant="outlined"
                  onClick={isOpen.onClose}
                >
                  Close
                </MDButton>
              </MDBox>
            </MDBox>
            </MDBox>
          </MDBox>
        </Fade>
      </Modal>
    </>
  );
};

export default MyModal;