import React, { useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import { Fade, Backdrop, Modal } from '@mui/material';
import {Select, FormControl, InputLabel, MenuItem } from '@mui/material';
import styled from 'styled-components';
import { VpnLock } from '@mui/icons-material';
import { valid } from 'chroma-js';

const StyledSelect = styled(Select)`
  .MuiSelect-select {
    background-color: #f5f5f5;
    border-radius: 4px;
    padding: 12px 20px;
    font-size: 16px;
    height: 40px;
    box-sizing: border-box;
    width: 100%;
  }
`;
const MyModal = (isOpen) => { 

  const [data,setData]=useState([]);
  const [doctorId,setDoctorId]=useState('');
  const [name,setName]=useState('');
  const [selectedOption, setSelectedOption] = useState(isOpen.doctor_name?isOpen.doctor_name+'#3':"Select Doctor");

  ///console.log('doctor_name',selectedOption);

useEffect(() => {
  fetch(process.env.REACT_APP_API_URL + `/auth/getalldoctors`,)
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      setData(data);
    });
},[])

const handleChange = (event) => {

  //console.log("event target",event.target);
  const value=event.target.value;
  const split=value.split('#');

 setDoctorId(split[1]);
 setName(split[0]);
 setSelectedOption(event.target.value); 
};

const handleSave = () => {

  const booking_id=isOpen.booking_id;
fetch( process.env.REACT_APP_API_URL+`/booking/bookingupdate/${booking_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        doctor_id_fkey:doctorId,

      })
    })
    .then(response => response.json())
    .then(data => {
      if(data.message){
        isOpen.onUpdate({
          message:data.message,
          doctor_name:name
        });
      }else{
        isOpen.onUpdate({
          error:data.error
        });
      }
     
    })
   
};

const renderValue = (selected) => {
  ///console.log("selected>",selected)
  return selected.split('#')[0];
};

  return (
    <>
    <Modal
    open={isOpen.isOpen}
    onClose={isOpen.onClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
    <Fade in={isOpen.isOpen}>
      <MDBox
        component="div"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor="white"
        shadow="md"
        borderRadius="lg"
        p={4}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxWidth: '600px',
        }}
      >
        <MDBox width="100%">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MDTypography variant="h6">Assign Doctor</MDTypography>
              <FormControl fullWidth>
                <StyledSelect
                  id="demo-simple-select"
                  value={selectedOption}
                  onChange={handleChange}
                  renderValue={renderValue}
                >
                  <MenuItem value="Select Doctor">Select Doctor </MenuItem>
                  {data.map((item) => (
                 <MenuItem key={item.id} value= {item.firstname+" "+item.lastname+"#"+item.id} >
                   {item.firstname+" "+item.lastname}
                 </MenuItem>
               ))}
                </StyledSelect>
              </FormControl>
            </Grid>
          </Grid>
          <MDBox
            display="flex"
            justifyContent="flex-end"
            gap={2}
            mt={2}
          >
            <MDButton
              color="success"
              onClick={handleSave}
            >
              Assign
            </MDButton>
            <MDButton
              color="secondary"
              variant="outlined"
              onClick={isOpen.onClose}
            >
              Close
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Fade>
  </Modal>

    </>
  );
};

export default MyModal;