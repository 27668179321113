/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import MyModal from '../../components/ScanProviderEditPopUp'
import DeleteModal from '../../components/ScanProviderDeletePopUp'
import AddModel from '../../components/ScanProviderAddPopUp'
// Data
import scanprovidersTableData from "layouts/tables/data/scanprovidersTableData";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MDBadge from "components/MDBadge";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

function Tables() {

  const navigate = useNavigate();
  // const location = useLocation();

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [delete_id, setDeleteID] = useState('');

  const [id, setID] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [location,setLocation]=useState('')
  
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');

  const [isAddScanProviderOpen, setIsAddScanProviderOpen] = useState(false);
  const [successSB, setSuccessSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);


  const [errorSB, setErrorSB] = useState(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);




  const [search, setSearch] = useState('');



  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={title}
      content={message}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={title}
      content={message}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );
  const openEditPopup = (data) => {
console.log('data',data);
    if (!data.scanprovider_id) {
      setID(data.id.props.children.props.children);
      setAddress(data.address.props.children);
      setEmail(data.email.props.children);
      setLocation(data.city.props.children);
      setName(data.name.props.children);
      setPhone(data.phone_no.props.children)
      setIsEditOpen(true);
    } else {

      setID(data.scanprovider_id);
      setAddress(data.address);
      setEmail(data.email);
      setLocation(data.location);
      setName(data.name);
      setPhone(data.phone_no)
      setIsEditOpen(true);
    }
  }

  const openDeletePopUp = (data) => {
    console.log('deleeee',data);
    if (data.scanprovider_id) {
      setDeleteID(data.scanprovider_id);
      setIsDeleteOpen(true);
    } else {
      setDeleteID(data.id.props.children.props.children);
      setIsDeleteOpen(true);
    }

  }


  

  const openAddScanProvider=()=>{
    setIsAddScanProviderOpen(true);
  }


  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);


  const closeEditPopup = () => {
    setIsEditOpen(false);

  }
  const closeDeletePopup = () => {
    setIsDeleteOpen(false);

  }
  const CloseScanProviderPopUp = () => {
    setIsAddScanProviderOpen(false);
  }



  const column = { Header: "Action", accessor: "action" };
  const { columns: tableColumns, rows: tableRows } = scanprovidersTableData();
  //const row={edit: 'edit'}

  const update = (value) => {

    console.log('valuesss',value);
    if (value.message) {
      setIsEditOpen(false);
      updateRowById(value.scanprovider_id, value);
      setMessage(value.message);
      setTitle('Updated');
      openSuccessSB();
    } else {
      setIsEditOpen(false);
      // updateRowById(value.booking_id,value);
      setMessage(value.error);
      setTitle('Error');
      openErrorSB();
    }


  }


  const addscanprovider=(value)=>{
    console.log('valuess',value);
    setIsAddScanProviderOpen(false);
    //updateRowStatusById(value);  
    if(value.message){
      updateData(value.scanprovider_id,value);
      setMessage(value.message);
      setTitle('Added');
      openSuccessSB();
      }else if(value.error){
      setMessage(value.error);
      setTitle('Error');
      openErrorSB();
      }
  }

  const deleteupdate = (value) => {
    if (value.message) {
      setIsDeleteOpen(false);
      deleteRowById(value.id);
      setMessage(value.message);
      setTitle('Deleted');
      openSuccessSB();
    } else {
      setIsDeleteOpen(false);
      //deleteRowById(value.id);
      setMessage(value.error);
      setTitle('Error');
      openErrorSB();
    }

  }

  const deleteRowById = (id) => {
    console.log('id,,', id);
    setRows(prevRows => prevRows.filter(row => row.id.props.children.props.children !== id ? row : null));
  };
  useEffect(() => {

    if (rows.length > 0 || search != '') {
      return
    }
    const updatedRows = tableRows.map((row) => (

      {
        ...row, action:
          <MDTypography component="a" href="#" variant="caption" color="success" fontWeight="medium" >
            <span onClick={() => openEditPopup(row)}><EditIcon fontSize="medium" color="secondary" /> </span> 
            <span onClick={() => openDeletePopUp(row)}> <DeleteIcon fontSize="medium" color="error" /> </span>
          </MDTypography>
      }

    ))

    setColumns([...tableColumns, column]);
    setRows(updatedRows);

  }, [tableColumns, rows]);

  const updateRowById = (id, updatedValues) => {

    console.log('id',id);
    // Find the index of the row with the specific id
    const rowIndex = rows.findIndex(row => row.id.props.children.props.children === id);
  
    if (rowIndex !== -1) {
      // Update the row data immutably
      
      const updatedRows = rows.map((row, index) =>
        index === rowIndex
          ?{
            ...row,
              name: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{updatedValues.name}</MDTypography>,
              email: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{updatedValues.email}</MDTypography>,
              address: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{updatedValues.address}</MDTypography>,
              phone_no: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{updatedValues.phone_no}</MDTypography>,
              city: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{updatedValues.location}</MDTypography>,
              
              action:
                <MDTypography component="a" href="#" variant="caption" color="success" fontWeight="medium" >
                  <span onClick={() => openEditPopup(updatedValues)}><EditIcon fontSize="medium" color="secondary" /> </span> <span onClick={() => openDeletePopUp(updatedValues)}> <DeleteIcon fontSize="medium" color="error" /> </span>
                </MDTypography>,
              
            }
          : row
      );
  
      setRows(updatedRows);
    }
  }
  

  const updateData = (id,values) => {
   
    const newRow = {id: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{id}</MDTypography>,
      name: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{values.name}</MDTypography>,
      email: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{values.email}</MDTypography>,
      address: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{values.address}</MDTypography>,
      phone_no: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{values.phonenumber}</MDTypography>,
      city: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{values.location}</MDTypography>,
      
      action:
        <MDTypography component="a" href="#" variant="caption" color="success" fontWeight="medium" >
          <span onClick={() => openEditPopup(values)}><EditIcon fontSize="medium" color="secondary" /> </span> <span onClick={() => openDeletePopUp(values)}> <DeleteIcon fontSize="medium" color="error" /> </span>
        </MDTypography>,
    
}

 setRows([...rows, newRow]);

}
  const handleSearch = (query) => {

    const updatedRows = tableRows.map((row) => (

      {
        ...row, action:
          <MDTypography component="a" href="#" variant="caption" color="success" fontWeight="medium" >
            <span onClick={() => openEditPopup(row)}><EditIcon fontSize="medium" color="secondary" /> </span> <span onClick={() => openDeletePopUp(row)}> <DeleteIcon fontSize="medium" color="error" /> </span>
          </MDTypography>
      }

    ))

    console.log("updatedRows>", updatedRows)


    const searchTerm = query.toLowerCase();
    const filteredData = updatedRows.filter((row) => {
      //console.log(row.name.props.children.toLowerCase())
      const rowNameValue = row.name.props.children.toLowerCase();
      return rowNameValue.includes(searchTerm);
    });

    console.log("filteredData>", filteredData)

    setRows(filteredData);


  };



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  All Scan Providers
                </MDTypography>
              </MDBox>

              <MDBox pt={3}>


                <MDBox width="12rem" ml="auto" mr="1em">
                  <MDInput
                    placeholder="filter by patient name, status"
                    value={search}
                    size="small"
                    fullWidth
                    onChange={({ currentTarget }) => {
                      setSearch(currentTarget.value);
                      handleSearch(currentTarget.value);
                    }}
                  />
                </MDBox>

                <MDBox width="12rem" ml="auto" mr="1em" mt='2rem'>
              <MDButton variant="gradient" color="info" onClick={openAddScanProvider}  >Add Scan Provider</MDButton>
              </MDBox>

                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={10}
                  showTotalEntries={true}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>

        </Grid>
      </MDBox>
      <Footer />
      <MyModal isOpen={isEditOpen} onClose={closeEditPopup} id={id} name={name} email={email} address={address} location={location} phone={phone} onUpdate={update} />
      <DeleteModal isdeleteOpen={isDeleteOpen} ondeleteClose={closeDeletePopup} delete_id={delete_id} ondeleteUpdate={deleteupdate} />
      <AddModel isOpen={isAddScanProviderOpen} onaddClose={CloseScanProviderPopUp} onAdd={addscanprovider}/>
      {renderSuccessSB}
      {renderErrorSB}
    </DashboardLayout>
  );
}

export default Tables;
