/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import { grey } from '@mui/material/colors';

//import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useState,useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Type } from "ajv/dist/compile/subschema";


export default function data() {
  const [user, setUser] = useState([]);

  const [userId,setUserId]=useState('');
  const navigate = useNavigate(); // Utilize useNavigate for redirection
  
 
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const storedToken = localStorage.getItem('jwt_token');
    if (storedUser && storedToken) {
      try {
    
          fetch( process.env.REACT_APP_API_URL+`/auth/getallcenters`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${storedToken}`
            }
          })
          .then(response => response.json())
          .then(data => {
            console.log('centererererererererer',data);
            data.sort((center1, center2) => center1.id - center2.id);
            setUser(data);
            })
        
      } catch (error) {
        console.error('Error parsing stored user data:', error);
      }
    } else {
      // No user or token found, redirect to login page
      navigate('/authentication/sign-in'); // Assuming your login page path is '/login'
    }
    
  }, []); 

return {
  
    columns: [
      
      { Header: "ID", accessor: "id", align: "left" },
      { Header: "Name ", accessor: "name",  align: "left" },
      { Header: "Address", accessor: "address", align: "center" },
      { Header: "Scan Provider", accessor: "scanprovider", align: "center" },
      { Header: "Phone Number", accessor: "phone", align: "center" },
      { Header: "Post Code", accessor: "post_code", align: "center" },
     

    ],

    rows: user.map((item) => (
      {
        id: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {item.id}
          </MDTypography>
        ),

        name: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {item.name}
          </MDTypography>
        ),

        address: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {item.address}
          </MDTypography>
        ),


      
        phone: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {item.phone_number}
          </MDTypography>
      
        ),
        scanprovider: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {item.scanproviders!==null ? item.scanproviders[0].scanprovider.name:'null'}
          </MDTypography>
      
        ),
        post_code: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
           {item.location}
          </MDTypography>
        ),
       status:item.status,
       email:item.email
      })),

    
  };
 
}
