/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import { grey } from '@mui/material/colors';

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useState,useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Type } from "ajv/dist/compile/subschema";


export default function data() {
  const [showModal, setShowModal] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [user, setUser] = useState([]);
  const navigate = useNavigate(); // Utilize useNavigate for redirection
 
  const handleDelete = (id) => {
    const result = window.confirm('Do you want to continue?');
    if(result){
     
     const patient_id=id;  
     fetch( process.env.REACT_APP_API_URL+`/patientdetails/deletepatient/${patient_id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
     })
     .then(response => response.json())
     .then(data => {
       alert(data.message);
     })
     
    }
   
  };
   
  const handleOpen = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleSave = () => {

    handleClose();
  };
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const storedToken = localStorage.getItem('jwt_token');

  
    if (storedUser && storedToken) {
      try {
          fetch( process.env.REACT_APP_API_URL+`/patientdetails/getallpatientsdetails`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${storedToken}`
            }
          })
          .then(response => response.json())
          .then(data => {
            data.sort((patient1, patient2) => patient1.id - patient2.id);
            setUser(data);
            })
        
      } catch (error) {
        console.error('Error parsing stored user data:', error);
      }
    } else {
      // No user or token found, redirect to login page
      navigate('/authentication/sign-in'); // Assuming your login page path is '/login'
    }
    
  }, []); 
 


  return {
    columns: [
      { Header: "ID", accessor: "id", align: "left" },
      { Header: "Name ", accessor: "name", align: "left" },
      { Header: "Email", accessor: "email", align: "center" },
      { Header: "Address", accessor: "address", align: "center" },
      { Header: "Phone", accessor: "phone", align: "center" },
      { Header: "Gender", accessor: "gender", align: "center" },
      
    ],

    rows: user.map((item) => (
      {
        id: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {item.id}
          </MDTypography>
        ),

        name: (
          item.firstname ==""? ('Null'):(
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {item.firstname+" "+item.lastname}
          </MDTypography>)
        ),

        email: (
          item.email==""? ('Null'):(
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {item.email}
          </MDTypography>)
      
        ),
        address: (
          item.address==null? ('Null'):(
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
           {item.address}
          </MDTypography> )
        ),
        phone: (
          item.phone_numbers ==''? ('Null'):(
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
           {item.phone_numbers}
          </MDTypography>)
        ),
        gender: (
          item.gender ==null? ('Null'):(
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
             {item.gender}
          </MDTypography>)
        ),
        status: item.status,
      })),

    
  };
}
