/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import { grey } from '@mui/material/colors';

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Type } from "ajv/dist/compile/subschema";
//import { format } from 'date-fns'; 

export default function data() {
  const [data, setData] = useState([]);
  const navigate = useNavigate(); // Utilize useNavigate for redirection

  // console.log(user[2].patient_detail.firstname);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const storedToken = localStorage.getItem('jwt_token');
    if (storedUser && storedToken) {
      try {
        const parsedUser = JSON.parse(storedUser);
        const user_id = parsedUser.id;
        fetch(process.env.REACT_APP_API_URL + `/blogs/posts`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${storedToken}`
          }
        })
          .then(response => response.json())
          .then(data => {
            // data.sort((provider1, provider2) => provider1.id - provider2.id);
            console.log('blogs data>>>>>>>>>>>>>>',data);
            setData(data.posts);

          })

      } catch (error) {
        console.error('Error parsing stored user data:', error);
      }
    } else {
      // No user or token found, redirect to login page
      navigate('/authentication/sign-in'); // Assuming your login page path is '/login'
    }

  }, []);


  return {

    columns: [
      { Header: "Post ID", accessor: "post_id", align: "left" },
      { Header: "Title", accessor: "title", align: "left" },
      //make body hidden if you dont want to show it
      // { Header: "Body", accessor: "body", align: "left", hidden: true },
      { Header: "Summary", accessor: "summary", align: "left" },
      { Header: "SEO Title", accessor: "seo_title", align: "left" },
      // { Header: "Meta Description", accessor: "meta_description", align: "left" },
      // { Header: "Featured Image URL", accessor: "featured_image_url", align: "left" },
      // { Header: "Featured Image Alt", accessor: "featured_image_alt", align: "left" },
      { Header: "Author", accessor: "author", align: "center" },
      { Header: "Published At", accessor: "published_at", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Tags", accessor: "tags", align: "center" },
      { Header: "Categories", accessor: "categories", align: "center" },
    ],


    rows: data.map((item) => (
      {
        post_id: (

          <MDTypography variant="caption" fontWeight="medium">
            <Link to={`/skannr_blogs/post/${item.slug}`}>
              {item.post_id}
            </Link>
          </MDTypography>
        ),

        title: (
          item.title == '' ? ('Not Provided') : (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {item.title}
            </MDTypography>
          )
        ),

        // body: (
        //   item.body == '' ? ('Not Provided') : (
        //     <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        //       {/* this blog body make to show just starting some words then its .... */
        //         item.body.substring(0, 50) + '...'}
        //     </MDTypography>
        //   )
        // ),

        summary: (
          item.summary == '' ? ('Not Provided') : (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {item.summary.substring(0, 50) + '...'}
            </MDTypography>
          )
        ),

        seo_title: (
          item.seo_title == '' ? ('Not Provided') : (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {item.seo_title}
            </MDTypography>
          )
        ),

        // meta_description: (
        //   item.meta_description == '' ? ('Not Provided') : (
        //     <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        //       {item.meta_description}
        //     </MDTypography>
        //   )
        // ),

        // featured_image_url: (
        //   item.featured_image_url == '' ? ('Not Provided') : (
        //     <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        //       {item.featured_image_url}
        //     </MDTypography>
        //   )
        // ),

        // featured_image_alt: (
        //     <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        //       {item.featured_image_alt == '' ? 'Not Provided' : item.featured_image_alt}
        //     </MDTypography>
        // ),

        author : item.author&&(
          item.author.first_name == '' ? ('Not Provided') : (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {item.author.first_name}
            </MDTypography>
          )
        ),

        published_at: (
          item.published_at == null ? ('Not Provided') : (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {item.published_at}
            </MDTypography>
          )
        ),

        status: (
          item.status == null ? ('Not Provided') : (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {item.status}
            </MDTypography>
          )
        ),

        tags: (
          item.tags == null ? ('Not Provided') : (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {item.tags.map((tag) => (
                <MDBadge key={tag.slug} color="info" variant="outlined" badgeContent={tag.name} />
              ))}
            </MDTypography>
          )
        ),

        categories: (
          item.categories == null ? ('Not Provided') : (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {item.categories.map((category) => (
                <MDBadge key={category.slug} color="info" variant="outlined" badgeContent={category.name} />
              ))}
            </MDTypography>
          )
        ),

        post_body: item.body,

      })),
  };
}
