import React, { useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import { Fade, Backdrop, Modal } from '@mui/material';

const MyModal = (isOpen) => {

  console.log('helllo',isOpen)
  const [name, setName] = useState("");
  const [address, setAddress] = useState( "");
  const [email, setEmail] = useState( "");
  const [phone, setPhone] = useState("");
  const [location,setLocation]=useState('');


  useEffect(()=>{
    setName(isOpen.name);
    setAddress(isOpen.address);
    setEmail(isOpen.email);
    setPhone(isOpen.phone);
    setLocation(isOpen.location);
  },[isOpen])

  const handleSave = (e) => {
console.log('hello',name,address,email,phone,location);
    e.preventDefault();

 

    
    const scanprovider_id = isOpen.id;
    fetch(process.env.REACT_APP_API_URL + `/scanproviders/updatescanprovider/${scanprovider_id}`, {
      method: 'PUT', 
     headers: {
        'Content-Type': 'application/json',
      },
      body:JSON.stringify({
        name:name,
        email:email,
        address:address,
        location:location,
        phonenumber:phone
      })
    })
      .then(response => response.json())
      .then(data => {

        if(data.message){
          console.log(data.message);

          isOpen.onUpdate({
            message: data.message,
            name:name,
            address:address,
            email: email,
            phone_no: phone,
            location:location,
            scanprovider_id:scanprovider_id
          });
        }else{
        
          isOpen.onUpdate({
            error: data.error,
          
          });
        }
       
      })

  };
  return (
    <>


      <Modal
        open={isOpen.isOpen}
        onClose={isOpen.onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen.isOpen}>
          <MDBox
            component="div"
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgColor="white"
            shadow="md"
            borderRadius="lg"
            p={4}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              maxWidth: '600px',
            }}
          >
            <MDBox>
            <MDBox component="form" role="form" onSubmit={handleSave}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  
                  <MDInput
                    placeholder="Name"
                    value={name}
                    style={{ width: '100%' }}
                    onChange={(event) => setName(event.target.value)}
                  />
                </Grid>
               
                <Grid item xs={12}>
                
                  <MDInput
                    placeholder="Email"
                    type="email"
                    value={email}
                    style={{ width: '100%' }}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                
                <MDInput
                  placeholder="First line of Address"
                  value={address}
                  style={{ width: '100%' }}
                  onChange={(event) => setAddress(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                  <MDInput
                    placeholder="Town/City"
                    value={location}
                    style={{ width: '100%' }}
                    onChange={(event) => setLocation(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MDInput
                    placeholder="Phone Number"
                    value={phone}
                    style={{ width: '100%' }}
                    onChange={(event) => setPhone(event.target.value)}
                  />
                </Grid>

               

              </Grid>
              <MDBox
                display="flex"
                justifyContent="flex-end"
                gap={2}
                mt={2}
              >
                <MDButton
                  type="submit"
                  color="success"
                 
                >
                  Update
                </MDButton>
                <MDButton
                  color="secondary"
                  variant="outlined"
                  onClick={isOpen.onClose}
                >
                  Close
                </MDButton>
              </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </Fade>
      </Modal>
    </>
  );
};

export default MyModal;