/*
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Box, CardContent, CircularProgress, Divider, FormControl, Icon, InputAdornment, InputLabel, MenuItem, Select, Tooltip, Typography } from "@mui/material";
import { useParams } from 'react-router-dom';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import { Edit, Instagram, LinkedIn, Pinterest, Twitter } from "@mui/icons-material";
import MDInput from "components/MDInput";
import { Editor } from "@tinymce/tinymce-react";
import avatar from "assets/theme/components/avatar";

import CreatableSelect from 'react-select/creatable';

//need to modifidy the addblogpost page to post required data to api endpoint to add new blog post
function AddBlogPost() {
  const [popupOpen, setPopupOpen] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const [blogPost, setBlogPost] = useState({ author: {} });

  const [title, setTitle] = useState('');
  const [headTitle, setHeadTitle] = useState('');
  const [slug, setSlug] = useState('');
  const [body, setBody] = useState('');
  const [summary, setSummary] = useState('');
  const [seoTitle, setSeoTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [featuredImageUrl, setFeaturedImageUrl] = useState('');
  const [featuredImageAlt, setFeaturedImageAlt] = useState('');
  const [authorFirstName, setAuthorFirstName] = useState('');
  const [authorLastName, setAuthorLastName] = useState('');
  const [authorEmail, setAuthorEmail] = useState('');
  const [authorBio, setAuthorBio] = useState('');
  const [authorProfileImageUrl, setAuthorProfileImageUrl] = useState('');
  const [authorFacebookUrl, setAuthorFacebookUrl] = useState('');
  const [authorLinkedinUrl, setAuthorLinkedinUrl] = useState('');
  const [authorInstagramUrl, setAuthorInstagramUrl] = useState('');
  const [authorPinterestUrl, setAuthorPinterestUrl] = useState('');
  const [authorTwitterHandle, setAuthorTwitterHandle] = useState('');
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const [status, setStatus] = useState('draft');

  const [showHint, setShowHint] = useState(true);

  const [availableTags, setAvailableTags] = useState([]);
  const [availableCategories, setAvailableCategories] = useState([]);

  const [message, setMessage] = useState('');

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/blogs/tags')
      .then((response) => response.json())
      .then((data) => {
        setAvailableTags(data.tags);
      });

    fetch(process.env.REACT_APP_API_URL + '/blogs/categories')
      .then((response) => response.json())
      .then((data) => {
        setAvailableCategories(data.categories);
      });

  }, []);

  const postBlog = () => {  // Post Blog
    setLoading(true);
    // validate each field first
    if (!title || !slug || !body || !summary || !seoTitle || !metaDescription || !featuredImageUrl || !authorFirstName || !authorLastName || !authorEmail || !authorBio || !authorProfileImageUrl) {
      setLoading(false);
      setMessage("Please fill in all required fields.");
      setHeadTitle("Error");
      openErrorSB();
      return;
    }
    const formData = new FormData();
    formData.append('title', title);
    formData.append('slug', slug);
    formData.append('body', body);
    formData.append('summary', summary);
    formData.append('seo_title', seoTitle);
    formData.append('meta_description', metaDescription);
    formData.append('status', status);
    formData.append('featured_image', featuredImageUrl);
    formData.append('featured_image_alt', featuredImageAlt);
    formData.append('author_first_name', authorFirstName);
    formData.append('author_last_name', authorLastName);
    formData.append('author_email', authorEmail);
    formData.append('author_bio', authorBio);
    formData.append('author_profile_image', authorProfileImageUrl);
    formData.append('author_facebook_url', authorFacebookUrl);
    formData.append('author_linkedin_url', authorLinkedinUrl);
    formData.append('author_instagram_url', authorInstagramUrl);
    formData.append('author_pinterest_url', authorPinterestUrl);
    formData.append('author_twitter_handle', authorTwitterHandle);
    formData.append('tags', JSON.stringify(tags));
    formData.append('categories', JSON.stringify(categories));

    console.log('tags', tags);
    console.log('categories', categories);

try{
    fetch(process.env.REACT_APP_API_URL + '/blogs/posts', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setMessage(data.message);
        setHeadTitle("Success")
        openSuccessSB();
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false);
        setMessage("Failed to post blog");
        setHeadTitle("Error")
        openErrorSB();
      });
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      setMessage("Failed to post blog");
      setHeadTitle("Error")
      openErrorSB();
    }
  };

  const autoFetchAuthor = (event) => {
    setAuthorEmail(event.target.value);
    // Fetch Author Details
    // check if the email is valid with ragular expression till end of the email domain and extesnion as well .com or what so ever

    if (!event.target.value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
      return;
    }

    fetch(process.env.REACT_APP_API_URL + '/blogs/authers/byEmail/' + event.target.value)
      .then((response) => response.json())
      .then((data) => {
        data = data.author;
        setAuthorFirstName(data.first_name);
        setAuthorLastName(data.last_name);
        setAuthorBio(data.bio);
        setAuthorProfileImageUrl(data.profile_image_url);
        setAuthorFacebookUrl(data.facebook_url);
        setAuthorLinkedinUrl(data.linkedin_url);
        setAuthorInstagramUrl(data.instagram_url);
        setAuthorPinterestUrl(data.pinterest_url);
        setAuthorTwitterHandle(data.twitter_handle);
      });
  };

  const handleChange = (newValue) => {
    console.log(newValue);
    setCategories(newValue);
  };

  const handleCreate = (inputValue) => {
    const newCategory = { value: inputValue.toLowerCase().replace(/\s+/g, '-'), label: inputValue };
    setCategories([...categories, newCategory]);
    categoryOptions.push(newCategory);
  };

  const categoryOptions = availableCategories.map((category) => ({
    value: category.slug,
    label: category.name,
  }));

  const handleTagChange = (newValue) => {
    console.log(newValue);
    setTags(newValue);
  };

  const handleTagCreate = (inputValue) => {
    console.log(inputValue);
    const newTag = { value: inputValue.toLowerCase().replace(/\s+/g, '-'), label: inputValue };
    setTags([...tags, newTag]);
    tagsOptions.push(newTag);
  };

  const tagsOptions = availableTags.map((tag) => ({
    value: tag.slug,
    label: tag.name,
  }));

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={headTitle}
      content={message}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={headTitle}
      content={message}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );
     
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box p={3}>

        <Divider sx={{ my: 3 }} />


        {/* Main Content */}
        <Box bgcolor="white" borderRadius="lg">
          <Grid container spacing={3}>

            <Grid item xs={12} md={8}>
              <MDBox

                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Add New Blog
                </MDTypography>
              </MDBox>
              <Box mb={3}>
                {
                  featuredImageUrl ?
                    <MDBox
                      component="img"
                      src={URL.createObjectURL(featuredImageUrl)}
                      alt={"Featured Image"}
                      width="100%"
                      borderRadius="lg"
                    />
                    : ""
                }

                <Grid container spacing={2} mt={1}>
                  {/* Each Row */}
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" sx={{ borderBottom: '1px solid grey', pb: 1 }}>
                      {/* Label on the left side */}
                      <Typography variant="body1" sx={{ fontSize: { xs: '12px', sm: '15px', md: '16px' }, fontWeight: 'bold' }}>
                        Select Featured Image
                      </Typography>
                      {/* Details on the right side */}
                      <Box display="flex" flexDirection="column" alignItems="flex-end">
                        <MDInput
                          variant="outlined"
                          type="file"
                          onChange={(event) => {
                            console.log(event.target.files[0]);
                            setFeaturedImageUrl(event.target.files[0])
                          }}
                          fullWidth
                          required
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" sx={{ borderBottom: '1px solid grey', pb: 1 }}>
                      <MDInput
                        variant="outlined"
                        label="Title"
                        placeholder="Enter Blog Title"
                        value={title}
                        onChange={(event) => {
                          setSlug(event.target.value.toLowerCase().replace(/ /g, '-'))
                          setTitle(event.target.value)
                        }}
                        fullWidth
                        required
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" sx={{ borderBottom: '1px solid grey', pb: 1 }}>
                      <MDInput
                        variant="outlined"
                        label="SEO Title"
                        placeholder="Enter SEO Title"
                        value={seoTitle}
                        onChange={(event) => {
                          setSeoTitle(event.target.value)
                        }}
                        fullWidth
                        required
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" sx={{ borderBottom: '1px solid grey', pb: 1 }}>
                      <FormControl fullWidth variant="outlined" sx={{ minWidth: 120 }}>
                        <InputLabel>Status</InputLabel>
                        <Select
                          value={status}
                          onChange={(event) => setStatus(event.target.value)}
                          label="Status"
                          sx={{ height: 40 }}
                        >
                          <MenuItem value="draft">Draft</MenuItem>
                          <MenuItem value="published">Published</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" sx={{ borderBottom: '1px solid grey', pb: 1 }}>
                      <MDInput
                        variant="outlined"
                        label="Slug"
                        placeholder="Enter Blog Slug"
                        value={slug}
                        onChange={(event) => setSlug(event.target.value)}
                        fullWidth
                        required
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" sx={{ borderBottom: '1px solid grey', pb: 1 }}>

                      <MDInput
                        variant="outlined"
                        label="Summary"
                        placeholder="Enter Blog Summary"
                        value={summary}
                        onChange={(event) => setSummary(event.target.value)}
                        fullWidth
                        required
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" sx={{ borderBottom: '1px solid grey', pb: 1 }}>

                      <MDInput
                        variant="outlined"
                        label="Meta Description"
                        placeholder="Enter Meta Description"
                        value={metaDescription}
                        onChange={(event) => setMetaDescription(event.target.value)}
                        fullWidth
                        required
                      />

                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" sx={{ borderBottom: '1px solid grey', pb: 1 }}>
                      <FormControl fullWidth variant="outlined">
                        <CreatableSelect
                          isMulti
                          value={tags}
                          onChange={handleTagChange}
                          onCreateOption={handleTagCreate}
                          options={tagsOptions}
                          placeholder="Select or create tags"
                          styles={{
                            control: (base) => ({
                              ...base,
                              height: 50,
                              minHeight: 50,
                              zIndex: 10,
                            }),
                            menu: (base) => ({
                              ...base,
                              zIndex: 10,
                            }),
                          }}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" sx={{ borderBottom: '1px solid grey', pb: 1 }}>
                      <FormControl fullWidth variant="outlined">
                        <CreatableSelect
                          isMulti
                          value={categories}
                          onChange={handleChange}
                          onCreateOption={handleCreate}
                          options={categoryOptions}
                          placeholder="Select or create categories"
                          styles={{
                            control: (base) => ({
                              ...base,
                              height: 50,
                              minHeight: 50,
                              zIndex: 5,
                            }),
                            menu: (base) => ({
                              ...base,
                              zIndex: 5,
                            }),
                          }}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>

              </Box>

              <Divider sx={{ my: 3 }} />
              <Box mb={3}>
                <MDBox

                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >

                  <MDTypography variant="h6" color="white">
                    Blog Body
                  </MDTypography>
                </MDBox>
                <Editor
                  apiKey={process.env.REACT_APP_TINY_API}
                  initialValue="<h1>Write Blog Title </h1>"
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                      // Core editing features
                      'anchor', 'autolink', 'charmap', 'codesample', 'emoticons', 'image', 'link', 'lists', 'media', 'searchreplace', 'table', 'visualblocks', 'wordcount',
                      // Your account includes a free trial of TinyMCE premium features
                      // Try the most popular premium features until Nov 11, 2024:
                      'checklist', 'mediaembed', 'casechange', 'export', 'formatpainter', 'pageembed', 'a11ychecker', 'tinymcespellchecker', 'permanentpen', 'powerpaste', 'advtable', 'advcode', 'editimage', 'advtemplate', 'mentions', 'tableofcontents', 'footnotes', 'mergetags', 'autocorrect', 'typography', 'inlinecss', 'markdown',
                      // Early access to document converters
                      'importword', 'exportword', 'exportpdf'
                    ],
                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                    }}
                  onEditorChange={(content, editor) => {
                    setBody(content)
                  }}
                />

              </Box>

            </Grid>
            <Grid item xs={12} md={4}>
              <MDBox
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Author Details
                </MDTypography>
              </MDBox>
              <Card>
                <CardContent>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    textAlign="center"
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <MDInput
                          variant="outlined"
                          type="file"
                          onChange={(event) => {
                            setAuthorProfileImageUrl(event.target.files[0])
                          }}
                          fullWidth
                          required
                        />
                      </Grid>

                      <Grid item xs={12}>
                        {/* Profile Image */
                          authorProfileImageUrl && (
                            <MDBox
                              component="img"
                              src={typeof authorProfileImageUrl == 'string' ? authorProfileImageUrl : URL.createObjectURL(authorProfileImageUrl)}
                              alt={"Profile Image"}
                              width="50%"
                              borderRadius="lg"
                            />
                          )
                        }
                      </Grid>
                      <Grid item xs={12}>
                        <Tooltip title="Enter existing auther email or create new one">
                        <MDInput  // Author Email
                          variant="outlined"
                          label="Email"
                          placeholder="Fetch Author Details if valid"
                          value={authorEmail}
                          onChange={autoFetchAuthor}
                          fullWidth
                          required
                        />
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12}>
                        <MDInput  // Author First Name
                          variant="outlined"
                          label="First Name"
                          placeholder="Enter First Name"
                          value={authorFirstName}
                          onChange={(event) => setAuthorFirstName(event.target.value)}
                          fullWidth
                          required
                        
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MDInput  // Author Last Name
                          variant="outlined"
                          label="Last Name"
                          placeholder="Enter Last Name"
                          value={authorLastName}
                          onChange={(event) => setAuthorLastName(event.target.value)}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MDInput  // Author Bio
                          variant="outlined"
                          label="Bio"
                          placeholder="Enter Bio"
                          value={authorBio}
                          onChange={(event) => setAuthorBio(event.target.value)}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MDInput  // Author Facebook URL
                          variant="outlined"
                          label="Facebook URL"
                          placeholder="Enter Facebook URL"
                          value={authorFacebookUrl}
                          onChange={(event) => setAuthorFacebookUrl(event.target.value)}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <FacebookRoundedIcon color="info" fontSize={"medium"} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MDInput  // Author LinkedIn URL
                          variant="outlined"
                          label="LinkedIn URL"
                          placeholder="Enter LinkedIn URL"
                          value={authorLinkedinUrl}
                          onChange={(event) => setAuthorLinkedinUrl(event.target.value)}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LinkedIn color="info" fontSize={"medium"} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MDInput  // Author Instagram URL

                          variant="outlined"
                          label="Instagram URL"
                          placeholder="Enter Instagram URL"
                          value={authorInstagramUrl}
                          onChange={(event) => setAuthorInstagramUrl(event.target.value)}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Instagram color="primary" fontSize={"medium"} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MDInput  // Author Pinterest URL
                          variant="outlined"
                          label="Pinterest URL"
                          placeholder="Enter Pinterest URL"
                          value={authorPinterestUrl}
                          onChange={(event) => setAuthorPinterestUrl(event.target.value)}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Pinterest color="primary" fontSize={"medium"} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MDInput  // Author Twitter Handle

                          variant="outlined"
                          label="Twitter Handle"
                          placeholder="Enter Twitter Handle"
                          value={authorTwitterHandle}
                          onChange={(event) => setAuthorTwitterHandle(event.target.value)}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Twitter color="info" fontSize={"medium"} />
                              </InputAdornment>
                            ),
                          }}
                        />

                      </Grid>
                    </Grid>

                  </MDBox>
                </CardContent>
              </Card>

              <MDBox mt={3}>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => postBlog()}
                  fullWidth
                >
                  <CircularProgress color="secondary" size={20} style={{ display: loading ? 'block' : 'none' }} />
                  Post Blog
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
      {renderSuccessSB}
      {renderErrorSB}
    </DashboardLayout>

  );
};

export default AddBlogPost;
