/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

import EditProfile from "../../components/EditProfilePopUp";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useEffect, useState } from "react";

function Overview() {

const [data,setData]=useState([]);
const [editPopUp,showEditPopUp]=useState(false);
const [adminId,setAdminId]=useState('');


  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const storedToken = localStorage.getItem('jwt_token');
    if (storedUser && storedToken) {
      try {
        const parsedUser = JSON.parse(storedUser);
        const user_id = parsedUser.id;
       setAdminId(user_id);
        fetch(process.env.REACT_APP_API_URL + `/auth/getadminbyid/${user_id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${storedToken}`
          }
        })
          .then(response => response.json())
          .then(data => {
            setData(data);
            console.log('dataa',data);
          })

      } catch (error) {
        console.error('Error parsing stored user data:', error);
      }
    } else {
      // No user or token found, redirect to login page
      navigate('/authentication/sign-in'); // Assuming your login page path is '/login'
    }

  }, []);

  const handleEditProfileClick=()=>{

   showEditPopUp(true);
  }

  const handleclosePopUp=()=>{
    showEditPopUp(false);
  }

  const update_result=(data)=>{
    showEditPopUp(false);
    if(data.message){
      console.log(data.update_data);
      setData(data.update_data);
    }else{

    }
  }


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header image={data.image} name={data.firstname+" "+data.lastname}>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            {/* <Grid item xs={12} md={6} xl={4}>
              <PlatformSettings />
            </Grid> */}
            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              {/* <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} /> */}
              <ProfileInfoCard
                title="profile information"
                description={data.description}
                info={{
                  fullName: data.firstname+" "+data.lastname,
                  mobile: data.phonenumber,
                  email: data.email,
                  location: data.location,

                }}
                social={[
                  {
                    link: "https://www.facebook.com/CreativeTim/",
                    icon: <FacebookIcon />,
                    color: "facebook",
                  },
                  {
                    link: "https://twitter.com/creativetim",
                    icon: <TwitterIcon />,
                    color: "twitter",
                  },
                  {
                    link: "https://www.instagram.com/creativetimofficial/",
                    icon: <InstagramIcon />,
                    color: "instagram",
                  },
                ]}
                action={{ route: "", tooltip: "Edit Profile" , onclick: handleEditProfileClick}}
                shadow={false}
              />
            
            </Grid>
            
          </Grid>
        </MDBox>
       
      </Header>
      <Footer />
      <EditProfile isOpen={editPopUp} onClose={handleclosePopUp} admin_id={adminId}  onUpdate={update_result} />
    </DashboardLayout>
    
  );
}

export default Overview;
