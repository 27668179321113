/*
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Box, CardContent, Divider, Typography } from "@mui/material";
import { useParams } from 'react-router-dom';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import { Instagram, LinkedIn, Pinterest, Twitter } from "@mui/icons-material";
import parse from 'html-react-parser';

function BlogPost() {

  const [ispopupOpen, setPopupOpen] = useState(false);

  const [doctorname, setDoctorname] = useState('');
  const [successSB, setSuccessSB] = useState(false);

  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  let count = 1;
  const closePopup = () => {
    setPopupOpen(false);
  }


  const { slug } = useParams();
  const [blogPost, setBlogPost] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {

    fetch(process.env.REACT_APP_API_URL + `/blogs/posts/${slug}`)
      .then(response => response.json())
      .then(data => {
        console.log("Blog Post >>>>>>>>>>>>>", data);
        setBlogPost(data.post);
        setLoading(true);
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false);
      });

  }, []);

  const update = (value) => {
    setPopupOpen(false);
    setDoctorname(value.doctor_name);
    setMessage('Doctor Assigned');
    setTitle('Updated');
    openSuccessSB();
    //  console.log('return values>>>>>>>>',value);

  }

  const openPopup = () => {
    setPopupOpen(true);
  }



  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={title}
      content={message}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const viewreferralreport = () => {
    window.open(blogPost.referralfilepath, '_blank');
  }
  const viewdocotorreport = () => {
    window.open(blogPost.doctorfilepath, '_blank');
  }
  const viewpatinetreport = () => {
    window.open(blogPost.filepath, '_blank');
  }

  const viewcenterreport = () => {
    window.open(blogPost.centerreport, '_blank');
  }

  if (loading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <Box p={3}>

          <Divider sx={{ my: 3 }} />


          {/* Main Content */}
          <Box bgcolor="white" borderRadius="lg">
            <Grid container spacing={3} xs={20}>

              <Grid item xs={12} md={8}>
                <MDBox

                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    {blogPost.title}
                  </MDTypography>
                </MDBox>
                <Box mb={3}>
                  {/* featured_image_url image Here */}
                  <MDBox
                    component="img"
                    src={blogPost.featured_image_url}
                    alt={blogPost.featured_image_alt}
                    width="100%"
                    borderRadius="lg"
                  />
                  <Grid container spacing={2} mt={1}>
                    {/* Each Row */}
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="space-between" sx={{ borderBottom: '1px solid grey', pb: 1 }}>
                        {/* Label on the left side */}
                        <Typography variant="body1" sx={{ fontSize: { xs: '12px', sm: '15px', md: '16px' }, fontWeight: 'bold' }}>
                          SEO Title
                        </Typography>
                        {/* Details on the right side */}
                        <Box display="flex" flexDirection="column" alignItems="flex-end">
                          <Typography variant="body2" sx={{ fontSize: { xs: '12px', sm: '15px', md: '16px' }, fontWeight: 'bold' }}>

                            {blogPost.seo_title ? blogPost.seo_title : ''}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="space-between" sx={{ borderBottom: '1px solid grey', pb: 1 }}>
                        <Typography variant="body1" sx={{ fontSize: { xs: '12px', sm: '15px', md: '16px' }, fontWeight: 'bold' }}>

                          Published At
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: { xs: '12px', sm: '15px', md: '16px' } }}>{blogPost.published_at ? blogPost.published_at : ''}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="space-between" sx={{ borderBottom: '1px solid grey', pb: 1 }}>
                        <Typography variant="body1" sx={{ fontSize: { xs: '12px', sm: '15px', md: '16px' }, fontWeight: 'bold' }}>

                          Status
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: { xs: '12px', sm: '15px', md: '16px' } }}>{blogPost.status ? blogPost.status.toUpperCase() : ''}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="space-between" sx={{ borderBottom: '1px solid grey', pb: 1 }}>
                        <Typography variant="body1" sx={{ fontSize: { xs: '12px', sm: '15px', md: '16px' }, fontWeight: 'bold' }}>

                          Slug
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: { xs: '12px', sm: '15px', md: '16px' } }}>{blogPost.slug ? blogPost.slug : ''}</Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="space-between" sx={{ borderBottom: '1px solid grey', pb: 1 }}>
                        <Typography variant="body1" sx={{ fontSize: { xs: '12px', sm: '15px', md: '16px' }, fontWeight: 'bold' }}>

                          Summary
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: { xs: '12px', sm: '15px', md: '16px' } }}>{blogPost.summary ? blogPost.summary : ''}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="space-between" sx={{ borderBottom: '1px solid grey', pb: 1 }}>
                        <Typography variant="body1" sx={{ fontSize: { xs: '12px', sm: '15px', md: '16px' }, fontWeight: 'bold' }}>

                          Meta Description
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: { xs: '12px', sm: '15px', md: '16px' } }}>{blogPost.meta_description ? blogPost.meta_description : ''}</Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="space-between" sx={{ borderBottom: '1px solid grey', pb: 1 }}>
                        {/* Label on the left side */}
                        <Typography variant="body1" sx={{ fontSize: { xs: '12px', sm: '15px', md: '16px' }, fontWeight: 'bold' }}>Tags</Typography>
                        {/* Details on the right side */}
                        <Box display="flex" flexDirection="column" alignItems="flex-end">
                          {blogPost.tags ? (
                            blogPost.tags.map((item) => (
                              <Typography sx={{ fontSize: { xs: '12px', sm: '15px', md: '16px' } }} key={item.name} variant="body2">
                                {item.name}
                              </Typography>
                            ))
                          ) : (
                            <Typography sx={{ fontSize: { xs: '12px', sm: '15px', md: '16px' } }} variant="body2">No data available</Typography>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="space-between" sx={{ borderBottom: '1px solid grey', pb: 1 }}>
                        {/* Label on the left side */}
                        <Typography variant="body1" sx={{ fontSize: { xs: '12px', sm: '15px', md: '16px' }, fontWeight: 'bold' }}>Categories</Typography>
                        {/* Details on the right side */}
                        <Box display="flex" flexDirection="column" alignItems="flex-end">
                          {blogPost.categories ? (
                            blogPost.categories.map((item) => (
                              <Typography sx={{ fontSize: { xs: '12px', sm: '15px', md: '16px' } }} key={item.name} variant="body2">
                                {item.name}
                              </Typography>
                            ))
                          ) : (
                            <Typography sx={{ fontSize: { xs: '12px', sm: '15px', md: '16px' } }} variant="body2">No data available</Typography>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>

                </Box>

                <Divider sx={{ my: 3 }} />
                <Box mb={3}>
                  <MDBox

                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >

                    <MDTypography variant="h6" color="white">
                      Blog Body
                    </MDTypography>
                  </MDBox>
                  <MDBox p={2}>
                    <Typography variant="body1">
                      {parse(blogPost.body)}
                    </Typography>
                  </MDBox>
                </Box>

              </Grid>
              <Grid item xs={12} md={4}>
                <MDBox
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Author
                  </MDTypography>
                </MDBox>
                <Card>
                  <CardContent>
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      textAlign="center"
                    >
                      <MDBox
                        component="img"
                        src={blogPost.author ? blogPost.author.profile_image_url : "https://via.placeholder.com/150"}
                        alt={"Profile Image"}
                        width="25%"
                        borderRadius="lg"
                      />
                      <MDBox mt={2}>
                        <MDTypography variant="h6">
                          { blogPost.author ? blogPost.author.first_name : ''} { blogPost.author ? blogPost.author.last_name || '' : ''}
                        </MDTypography>
                        <MDTypography variant="body2">
                          {blogPost.author ? blogPost.author.email : ''}
                        </MDTypography>
                        {/* Social Media Links */}
                        <Box mt={2} display="flex" justifyContent="center">
                          <Grid container justifyContent="center">
                            <Grid item>
                              <MDButton
                                component="a"
                                href={blogPost.author ? blogPost.author.facebook_url : ''}
                                target="_blank"
                                rel="noopener noreferrer"
                                color="facebook"
                                variant="contained"
                                sx={{ mx: 1 }}
                              >
                                <FacebookRoundedIcon color={"info"} fontSize={"large"} />
                              </MDButton>
                            </Grid>
                            <Grid item>
                              <MDButton
                                component="a"
                                href={blogPost.author ? blogPost.author.linkedin_url: ''}
                                target="_blank"
                                rel="noopener noreferrer"
                                color="linkedin"
                                variant="contained"
                                sx={{ mx: 1 }}
                              >
                                <LinkedIn color={"info"} fontSize={"large"} />
                              </MDButton>
                            </Grid>
                            <Grid item>
                              <MDButton
                                component="a"
                                href={blogPost.author ? blogPost.author.instagram_url: ''}
                                target="_blank"
                                rel="noopener noreferrer"
                                color="instagram"
                                variant="contained"
                                sx={{ mx: 1 }}
                              >
                                <Instagram color={"primary"} fontSize={"large"} />
                              </MDButton>
                            </Grid>
                            <Grid item>
                              <MDButton
                                component="a"
                                href={blogPost.author ? blogPost.author.pinterest_url : ''}
                                target="_blank"
                                rel="noopener noreferrer"
                                color="pinterest"
                                variant="contained"
                                sx={{ mx: 1 }}
                              >
                                <Pinterest color={"primary"} fontSize={"large"} />
                              </MDButton>
                            </Grid>
                            <Grid item>
                              <MDButton
                                component="a"
                                href={`https://twitter.com/${ blogPost.author ? blogPost.author.twitter_handle : ''}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                color="twitter"
                                variant="contained"
                                sx={{ mx: 1 }}
                              >
                                <Twitter color={"info"} fontSize={"large"} />
                              </MDButton>
                            </Grid>
                          </Grid>
                        </Box>


                      </MDBox>
                    </MDBox>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Footer />
        {renderSuccessSB}
      </DashboardLayout>

    );
  };
}

export default BlogPost;
