/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import { grey } from '@mui/material/colors';

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Type } from "ajv/dist/compile/subschema";
//import { format } from 'date-fns'; 

export default function data() {
  const [data, setData] = useState([]);
  const navigate = useNavigate(); // Utilize useNavigate for redirection

  // console.log(user[2].patient_detail.firstname);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const storedToken = localStorage.getItem('jwt_token');
    if (storedUser && storedToken) {
      try {
        const parsedUser = JSON.parse(storedUser);
        const user_id = parsedUser.id;
        fetch(process.env.REACT_APP_API_URL + `/scanproviders/getallscanproviders`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${storedToken}`
          }
        })
          .then(response => response.json())
          .then(data => {
            data.sort((provider1, provider2) => provider1.id - provider2.id);
            console.log('data>>>>>>>>>>>>>>',data);
            setData(data);

          })

      } catch (error) {
        console.error('Error parsing stored user data:', error);
      }
    } else {
      // No user or token found, redirect to login page
      navigate('/authentication/sign-in'); // Assuming your login page path is '/login'
    }

  }, []);



  return {
    columns: [
      { Header: "ID", accessor: "id", align: "left" },
      { Header: "Name ", accessor: "name", align: "left" },
      { Header: "Email", accessor: "email", align: "center" },
      { Header: "Phone Number", accessor: "phone_no", align: "center" },
      { Header: "Address", accessor: "address", align: "center" },
      { Header: "Town/City", accessor: "city", align: "center" },
    
    ],


    rows: data.map((item) => (
      {
        id: (

          <MDTypography variant="caption" fontWeight="medium">
            <Link to={''}>
              {item.id}
            </Link>
          </MDTypography>
        ),

        name: (
          item.name == '' ? ('Not Provided') : (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {item.name}
            </MDTypography>
          )
        ),

        email: (
          item.email == null ? ('Not Provided') : (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {(item.email)}
            </MDTypography>
          )
        ),
        phone_no: (
          item.phonenumber == null ? ('Not Provided') : (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {item.phonenumber}
            </MDTypography>)
        ),
        address: (
          item.address == null ? ('Not Provided') : (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {item.address}
            </MDTypography>
          )
        ),
       
        city: (
          item.location == null ? ('Not Provided') : (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {item.location}
            </MDTypography>
          )
        ),
      })),
  };
}
