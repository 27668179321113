import React, { useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import { Fade, Backdrop, Modal } from '@mui/material';
import {Select, FormControl, InputLabel, MenuItem } from '@mui/material';
import styled from 'styled-components';
import { VpnLock } from '@mui/icons-material';
import { valid } from 'chroma-js';

const StyledSelect = styled(Select)`
  .MuiSelect-select {
    background-color: #f5f5f5;
    border-radius: 4px;
    padding: 12px 20px;
    font-size: 16px;
    height: 40px;
    box-sizing: border-box;
    width: 530px;
  }
`;
const MyModal = (isOpen) => { 


console.log('isOpen',isOpen);

  const [date, setDate] = useState(isOpen.date|| "");
  const [time, setTime] = useState(isOpen.time|| "");
  const [file,setFile]=useState({});

  console.log('file',file);
  const [selectedOption, setSelectedOption] = useState('cancelled');

  ///console.log('selected option>>>',selectedOption);
  let options=[];

   options = [
    { value: 'cancelled', label: 'Cancelled' },
    { value: 'pending', label: 'Pending' },
    { value: 'requested', label: 'Requested' },
    { value: 'approved', label: 'Approved' },
    { value: 'completed', label: 'Completed' },
    
  ];



const [day_name,setDayName]=useState('');
useEffect(()=>{
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const get_date = new Date(date);
  const dayIndex = get_date.getDay();

   setDayName(daysOfWeek[dayIndex]);

},[date])



  useEffect(()=>{
    setSelectedOption(isOpen.status);
    setDate(isOpen.date );
    setTime(isOpen.time);


 
  },[isOpen])
  const handleSave = () => {



    const booking_id=isOpen.id;
    if(isOpen.status=='approved'){

      let formData=new FormData();
      formData.append('report',file);
      formData.append('status','requested');

      fetch( process.env.REACT_APP_API_URL+`/booking/bookingupdatecenter/${booking_id.props.children}`, {
        method: 'PUT',
        body:formData
      })
      .then(response => response.json())
      .then(data => {
        if(data.message){
          isOpen.onUpdate({
            booking_id:booking_id,
            message:data.message, 
            status:'requested',
          });
        }else{
          isOpen.onUpdate({
            error:data.error, 
          });
        }
     
      })
     
    }else{
      fetch( process.env.REACT_APP_API_URL+`/booking/bookingupdatecenter/${booking_id.props.children}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          status:selectedOption,
          appointment_date:date,
          appointment_time:time,
          appointment_day:day_name,

        })
      })
      .then(response => response.json())
      .then(data => {
        if(data.message){
          isOpen.onUpdate({
            message:data.message,
            booking_id:booking_id,
            status:selectedOption,
            date:date,
            time:time,    
          });
        }else{
          isOpen.onUpdate({
            error:data.error, 
          });
        }
     
      })
     
    }
 
  };


  const handleChange = (event) => {
    //console.log('event',event.target);
    setSelectedOption(event.target.value);
    
  };


  return (
    <>
    

    <Modal
  open={isOpen.isOpen}
  onClose={isOpen.onClose}
  closeAfterTransition
  BackdropComponent={Backdrop}
  BackdropProps={{
    timeout: 500,
  }}
>
  <Fade in={isOpen.isOpen}>
  
    <MDBox
      component="div"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgColor="white"
      shadow="md"
      borderRadius="lg"
      p={4}
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxWidth: '600px',
      }}
    >
      <MDBox>
        
        <Grid container spacing={2} >

        {isOpen.status=='approved'?
        <>
        
        <Grid item xs={12}>
          <MDTypography>
             Upload Form
            </MDTypography>
            <MDInput
              type="file"
              style={{ width: '100%' }}
              onChange={(event) => setFile(event.target.files[0])}
            />
        </Grid>
        
          
      
        </>
        :
        <>
        <Grid item xs={12}>
        <MDTypography>
          Scan Date 
        </MDTypography>
        <MDInput
          placeholder="Date"
          type="date"
          value={date}
          style={{ width: '100%' }}
          onChange={(event) => setDate(event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
      <MDTypography>
         Status
        </MDTypography>
        <FormControl fullWidth>
          <StyledSelect
            id="demo-simple-select"
            value={selectedOption}
            onChange={handleChange}
          >
            {options.map((option) => (
             
              <MenuItem key={option.value} value={option.value} >
                {option.label}
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>
      </Grid>
  
      <Grid item xs={12}>
      <MDTypography>
         Scan Time
        </MDTypography>
        <MDInput
          placeholder="Time"
          type="time"
          value={time}
          style={{ width: '100%' }}
          onChange={(event) => setTime(event.target.value)}
        />
      </Grid>
        </>
        }
        

        </Grid>
  
        <MDBox
          display="flex"
          justifyContent="flex-end"
          gap={2}
          mt={2}
        >
          <MDButton
            color="success"
            onClick={handleSave}
          >
            Update
          </MDButton>
          <MDButton
            color="secondary"
            variant="outlined"
            onClick={isOpen.onClose}
          >
            Close
          </MDButton>
        </MDBox>
      </MDBox>
    </MDBox>
  </Fade>
</Modal>
    </>
  );
};

export default MyModal;