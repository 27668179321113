import React, { useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import { Fade, Backdrop, Modal } from '@mui/material';

const MyModal = (isOpen) => {

  const [name, setName] = useState(  "");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState(  "");
  const [phoneNumber, setPhoneNumber] = useState( "");
  const [location,setLocation]=useState('')




  const handleSave = () => {
  fetch( process.env.REACT_APP_API_URL+`/scanproviders/registerscanprovider`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name:name,
          email:email,
          phonenumber:phoneNumber,
          address:address,
          location:location
        })
      })
      .then(response => response.json())
      .then(data => {
        console.log(data);
      if(data.message){
        isOpen.onAdd({
          scanprovider_id:data.UserId,
          message:data.message,
          name:name,
          email:email,
          address:address,
          phonenumber:phoneNumber,
          location:location
        });
      }else{
        isOpen.onAdd({
          error:data.error,
        });
      }
        
      })
     
  };
  return (
    <>
    

      <Modal
        open={isOpen.isOpen}
        onClose={isOpen.onaddClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen.isOpen}>
          <MDBox
            component="div"
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgColor="white"
            shadow="md"
            borderRadius="lg"
            p={4}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              maxWidth: '600px',
            }}
          >
            
            <MDBox>
              <Grid container spacing={2}>
                <Grid item xs={12}>
              
                  <MDInput
                    placeholder="Name"
                    style={{ width: '100%' }} 
                    onChange={(event) => setName(event.target.value)}
                  />
                </Grid>
               
                
                <Grid item xs={12}>
               
                  <MDInput
                    placeholder="Email"
                    type="email"
                    style={{ width: '100%' }} 
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
               
                  <MDInput
                    placeholder="Phone Number"
                    style={{ width: '100%' }} 
                    onChange={(event) => setPhoneNumber(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
               
                  <MDInput
                    placeholder="First line of Address"
                    style={{ width: '100%' }} 
                    onChange={(event) => setAddress(event.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
               
                  <MDInput
                    placeholder="Town/City"
                    style={{ width: '100%' }} 
                    onChange={(event) => setLocation(event.target.value)}
                  />
                </Grid>            
                
              </Grid>
              <MDBox
                display="flex"
                justifyContent="flex-end"
                gap={2}
                mt={2}
              >
                <MDButton
                  color="success"
                  onClick={handleSave}
                >
                  Add 
                </MDButton>
                <MDButton
                  color="secondary"
                  variant="outlined"
                  onClick={isOpen.onaddClose}
                >
                  Close
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Fade>
      </Modal>
    </>
  );
};

export default MyModal;